<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      
      <!-- Organization Selector -->
      <v-col cols="12" sm="6" md="4">
        <ScopeSelector org-only @org-selected="onOrganizationSelected"></ScopeSelector>
      </v-col>

      <!-- LIST AND CREATE OPTIONS -->
      <v-col cols="12">

        <v-row>

          <!-- LIST OF PAYMENT SERVICES -->
          <v-col cols="7" lg="8">
            <SSRTable ref="ps-list" :data-url="loadUrl" :data-key="'paymentServices'" :schema="schema"
              :search="true">

              <div slot="service" slot-scope="{item}">
                <div v-if="getPaymentServiceIcon(item.service)">
                  <img :src="getPaymentServiceIcon(item.service)" style="height: 28px;">
                </div>
                <div v-else>{{ item.service }}</div>
              </div>

              <div slot="type" slot-scope="{item}">
                <div v-if="getPaymentServiceIcon(item.type)">
                  <img :src="getPaymentServiceIcon(item.type)" style="height: 28px;">
                </div>
                <div v-else>{{ item.type }}</div>
              </div>

              <div slot="use_tds" slot-scope="{item}">
                <v-icon v-if="item.use_tds">mdi-check</v-icon>
              </div>

              <div slot="actions" slot-scope="{item}">
                <v-btn rounded small elevation="0" color="warning" class="mx-1" @click="showUpdateDialog(item)">
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </div>
            </SSRTable>
          </v-col>

          <!-- CREATE OPTIONS -->
          <v-col cols="5" lg="4" class="pa-4">

            <v-card outlined>

              <v-list>

                <v-list-item v-for="item in creationList" :key="item.service" @click="foo = 'bar'">
                  <v-list-item-avatar size="96" rounded="0" class="mx-6">
                    <v-img contain :src="item.icon"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.title" class="text-h2"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.subtitle" class="text-h4"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="d-flex">
                    <v-btn fab small outlined color="#aaaaaa"
                      @click="showCreateDialog(item.service)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn fab small outlined color="#aaaaaa"
                      v-if="['linepay', 'easywallet', 'jko', 'icashpay', 'pxpayplus', 'pluspay', 'ipassmoney'].indexOf(item.service) >= 0"
                      @click="showBulkCreateDialog(item.service)">
                      <v-icon>mdi-plus-box-multiple-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

              </v-list>
            </v-card>

          </v-col>
        </v-row>

      </v-col>

    </v-row>

    <!-- DIALOG - PAYMENT-SERVICE CRUD -->
    <v-dialog v-model="dialog.crud.show" width="50%">
      <v-card>
        <v-card-title>
          <span v-if="dialog.crud.mode == 'create'">
            <strong>{{ $t('views.paymentServices.dialog.titleCreate', { service: $t('common.' + dialog.crud.service) }) }}</strong>
          </span>
          <span v-else>
            <strong>{{ $t('views.paymentServices.dialog.titleUpdate') }} |</strong>&nbsp;{{ dialog.crud.model.name }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-form ref="ps-form" lazy-validation v-model="dialog.crud.valid">
            <v-row>
              <!-- Radio Group - isOffline -->
              <v-col cols="12" v-if="dialog.crud.service !== 'tappay'">
                <v-radio-group v-model="dialog.crud.model.isOffline" @change="handleIsOfflineChange" row label="">
                  <v-radio label="線上" :value="false" v-show="supportTypes.online"></v-radio>
                  <v-radio label="線下" :value="true" v-show="supportTypes.offline"></v-radio>
                </v-radio-group>
              </v-col>

              <!-- Radio Group - TapPay payment types-->
              <v-col cols="12" v-if="dialog.crud.service === 'tappay' && dialog.crud.mode == 'create'">
                <v-radio-group v-model="dialog.crud.model.types" row label="Support Payment Types">
                  <!-- <v-radio v-for="type in supportedServices[dialog.crud.service].supportedTypes"
                    :key="type.val" :label="type.label" :value="type.val">
                  </v-radio> -->
                  <v-radio label="Apple Pay, Google Pay, Credit Cards" value="apple,google,direct"></v-radio>
                  <v-radio label="Credit Cards only" value="direct"></v-radio>
                  <v-radio label="JKO Pay only" value="jko"></v-radio>
                </v-radio-group>
              </v-col>

              <!-- SITUATIONAL TEXT FIELDS -->
              <v-col cols="12" md="6" v-for="item in crudFormItems" :key="item.key" 
                v-show="dialog.crud.model.isOffline ? item.display.includes('offline') : item.display.includes('online')"
              >
                <v-text-field outlined :label="item.label" v-model="dialog.crud.model[item.key]"
                  :disabled="dialog.crud.mode == 'update' && item.canUpdate === false"
                  :error-messages="validation.firstError('dialog.crud.model.' + item.key)"
                  >
                </v-text-field>
              </v-col>

              <!-- SITUATIONAL 3DS CHECKBOX -->
              <v-col cols="12" md="6" v-if="dialog.crud.service == 'tappay'">
                <v-checkbox v-model="dialog.crud.model.useTds" label="Use TDS?" color="success"
                  messages="Does this payment service require OTP or email security verification?">
                </v-checkbox>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="closeDialog" :disabled="dialog.crud.isProcessing">Cancel</v-btn>
          <v-btn color="amber" @click="createPaymentService" v-if="dialog.crud.mode == 'create'"
            :disabled="dialog.crud.isProcessing">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('views.paymentServices.dialog.buttonCreate') }}
          </v-btn>
          <div v-else>
            <v-btn color="error" @click="deletePaymentService" :disabled="dialog.crud.isProcessing">
              <v-icon left>mdi-delete</v-icon>
              {{ $t('views.paymentServices.dialog.buttonDelete') }}
            </v-btn>
            <v-btn color="amber" @click="updatePaymentService" :disabled="dialog.crud.isProcessing || dialog.crud.service == 'tappay'">
              <v-icon left>mdi-pencil</v-icon>
              {{ $t('views.paymentServices.dialog.buttonUpdate') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG - BULK CREATE -->
    <v-dialog v-model="dialog.bulk.show">
      <v-card>
        <v-card-title>
          <h3>Bulk-create&nbsp;
            <span v-if="dialog.bulk.service == 'linepay'">LINE Pay Services</span>
            <span v-if="dialog.bulk.service == 'easywallet'">EasyWallet Services</span>
            <span v-if="dialog.bulk.service == 'jko'">JKO Services</span>
            <span v-if="dialog.bulk.service == 'icashpay'">icash Pay Services</span>
            <span v-if="dialog.bulk.service == 'pxpayplus'">PxPay Services</span>
            <span v-if="dialog.bulk.service == 'pluspay'">Plus Pay Services</span>
            <span v-if="dialog.bulk.service == 'ipassmoney'">iPass Money Services</span>
          </h3>
        </v-card-title>
        <v-card-text>
          <BulkCreatePaymentServices :service="dialog.bulk.service"
            :org="org"
            :openDialog="dialog.bulk.show">
          </BulkCreatePaymentServices>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import ScopeSelector from '@/components/ScopeSelector';
import { GetIconMixin } from '@/mixins/get-icons';

// import UploadPreviewDialog from '@/components/UploadPreviewDialog';
import BulkCreatePaymentServices from '@/views/dashboard/subviews/BulkPaymentServices';

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';

const Validator = SimpleVueValidation.Validator;

export default {
  components: {
    SSRTable,
    ScopeSelector,
    // UploadPreviewDialog
    BulkCreatePaymentServices
  },
  mixins: [
    GetIconMixin
  ],

  mounted() {

  },

  methods: {
    onOrganizationSelected(org) {
      this.scope.organization = org;
    },
    handleIsOfflineChange(value) {
      this.dialog.crud.model.isOffline = value;
      // this.formItems = [];

      // Fill the text fields based on service's specific model spec.
      this.dialog.crud.validators = [];
      this.crudFormItems.forEach(item => {
        if (this.dialog.crud.model['isOffline'] && item.display.includes('offline')) {
          this.dialog.crud.validators.push(`dialog.crud.model.${item.key}`);
        } else if (!this.dialog.crud.model['isOffline'] && item.display.includes('online')){
          this.dialog.crud.validators.push(`dialog.crud.model.${item.key}`);
        }
      });
     
    },
    /// Methods for the dialog of creating/updating payment services.
    showCreateDialog(service) {
      // Check scope. An organization must be selected first (if it's superadmin).
      if (this.user['level'] === 'superadmin' && !this.scope.organization) {
        alert('Please select an organization first.');
        return;
      }          

      // Init dialog parameters and models.
      this.dialog.crud.service = service;
      this.dialog.crud.mode = 'create';
      this.dialog.crud.validators = [ ];

      if (service !== 'tappay'){ // 若有支援綿上，預設線上
        this.dialog.crud.model['isOffline'] = this.supportTypes.online ? false : true;
        // console.log('default isOffline:', this.supportTypes.online ? false : true )
      }

      // Init the text fields by initializing arguments.
      this.crudFormItems.forEach(item => {
        this.dialog.crud.model[ item.key ] = '';
        if (this.dialog.crud.model['isOffline'] && item.display.includes('offline')) {
          this.dialog.crud.validators.push(`dialog.crud.model.${item.key}`);
        } else if (!this.dialog.crud.model['isOffline'] && item.display.includes('online')){
          this.dialog.crud.validators.push(`dialog.crud.model.${item.key}`);
        }
       
      });

      if (service === 'tappay')
        this.dialog.crud.model['types'] = 'apple,google,direct';
      
      
      
      this.validation.reset();
      this.dialog.crud.show = true;
    },
    showUpdateDialog(paymentService) {
      console.log(`[DEBUG] Showing payment-service update dialog`, paymentService);
      this.dialog.crud.service = paymentService['service'];
      this.dialog.crud.mode = 'update';
      this.dialog.crud.paymentServiceId = paymentService['id'];
      this.dialog.crud.validators = [ ];

      // Fill the text fields based on service's specific model spec.
      if(typeof paymentService['isOffline'] === 'boolean'){
        this.dialog.crud.model['isOffline'] = paymentService['isOffline']
      }
      
      this.crudFormItems.forEach(item => {
        this.dialog.crud.model[ item.key ] = paymentService[ item.key ] || '';
        // this.dialog.crud.validators.push(`dialog.crud.model.${item.key}`);
        if (this.dialog.crud.model['isOffline'] && item.display.includes('offline')) {
          this.dialog.crud.validators.push(`dialog.crud.model.${item.key}`);
        } else if (!this.dialog.crud.model['isOffline'] && item.display.includes('online')){
          this.dialog.crud.validators.push(`dialog.crud.model.${item.key}`);
        }
      });
      

      this.validation.reset();
      this.dialog.crud.show = true;
    },
    showBulkCreateDialog(service) {
      // Check scope. An organization must be selected first (if it's superadmin).
      if (this.user['level'] === 'superadmin' && !this.scope.organization) {
        alert('Please select an organization first.');
        return;
      }
      this.dialog.bulk.service = service;
      this.dialog.bulk.show = true;
    },

    /// CRUD APIs
    createPaymentService() {
      
      this.$validate(this.dialog.crud.validators)
      .then(valid => {
        if (!valid) return;

        let model = this.dialog.crud.model;
        model['mode'] = this.defPaymentServiceMode || 'sandbox';
        model['service'] = this.dialog.crud.service;
        model['orgId'] = this.scope.organization? this.scope.organization['id']: undefined;

        // Resolve case dependent arguments.
        switch (this.dialog.crud.service) {
          case 'tappay': {
            model['types'] = this.dialog.crud.model['types'].split(',');
            break;
          }
        }
        console.warn(`[DEBUG] createPaymentService model`,  model);

        // Make the creation request.
        this.dialog.crud.isProcessing = true;
        axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.orgAlias}/payment-services`,
          model
        )
        .then(response => {
          this.$refs['ps-list'].loadData();
          this.$notify({
            text: `Payment Service created: ${model.name}.`,
            type: 'success'
          });
        })
        .catch(err => {
          console.error(`Failed to create a new payment service`, err);
          this.$notify({
            text: `Failed to create Payment Service: ${model.name}.`,
            type: 'error'
          });
        })
        .finally(() => {
          this.dialog.crud.isProcessing = false;
          // this.dialog.crud.show = false;
          this.closeDialog();
        });

      });
    },
    updatePaymentService() {

      this.$validate(this.dialog.crud.validators)
      .then(valid => {
        if (!valid) return;

        this.dialog.crud.isProcessing = true;
        let model = this.dialog.crud.model;
        model['service'] = this.dialog.crud.service;

        //FIXME: Make emptry strings "undefined"
        for (let key in model) {
          if (typeof model[ key ] === 'string' && !model[ key ].length)
            model[ key ] = undefined;
        }

        axios.put(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.orgAlias}/payment-services/${this.dialog.crud.paymentServiceId}`,
          model
        )
        .then(response => {
          this.$refs['ps-list'].loadData();
          this.$notify({
            text: `Payment Service updated: ${model.name}.`,
            type: 'success'
          });
        })
        .catch(err => {
          console.error(`Failed to update a payment service`, err);
          this.$notify({
            text: `Failed to update Payment Service: ${model.name}.`,
            type: 'error'
          });
        })
        .finally(() => {
          this.dialog.crud.isProcessing = false;
          //this.dialog.crud.show = false;
          this.closeDialog();
        });

      });
    },
    deletePaymentService() {
      if (!confirm(this.$t('views.paymentServices.dialog.alertDelete', { name: this.dialog.crud.model.name })))
        return;
      this.dialog.crud.isProcessing = true;
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.orgAlias}/payment-services/${this.dialog.crud.paymentServiceId}`,
        {
          data: {
            "orgId": this.scope.organization? this.scope.organization['id']: undefined
          }
        }
      )
      .then(response => {
        this.$refs['ps-list'].loadData();
        this.$notify({
          text: `Payment service deletion done.`,
          type: 'warn'
        });
      })
      .catch(err => {
        console.error(`Failed to delete a payment service`, err.response);
        this.$notify({
          text: `Failed to delete: ${err.response.data.message}`,
          type: 'error'
        });
      })
      .finally(() => {
        this.dialog.crud.isProcessing = false;
        this.closeDialog();
        // this.dialog.crud.show = false;
      });
    },
    closeDialog() {
      console.log('close dialog' );
      
      // reset to prevent displaying wrong value when next click;
      this.dialog.crud.show = false;
      // this.formItems = [];
      this.dialog.crud.model['isOffline'] = this.supportTypes.online ? false : true;
    }
  },

  data() {
    return {
      scope: {
        organization: null
      },
      // formItems:[],

      dialog: {
        crud: {
          show: false, isProcessing: false, valid: true, 
          mode: 'create',
          service: 'tappay',
          paymentServiceId: 0,
          model: {
            isOffline: false, 
          },
          validators: [ ]
        },

        bulk: {
          show: false,
          service: 'linepay' //default
        }
      }

    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    org() {
      if (this.user['level'] === 'superadmin' && this.scope.organization)
        return this.scope.organization;
      if (this.user['level'] === 'admin')
        return this.$store.getters.user['organization'];
      return null;
    },
    orgAlias() {
      if (this.user['level'] === 'superadmin' && this.scope.organization)
        return this.scope.organization['alias'];
      if (this.user['level'] === 'admin')
        return this.$store.getters.user['organization']['alias'];
      return null;
    },

    defPaymentServiceMode() {
      return `${process.env.VUE_APP_DEFAULT_PAYMENT_SERVICE_MODE}`;
    },

    loadUrl() {
      if (!this.orgAlias)
        return null;
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.orgAlias}/payment-services`
        + `?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`;
    },
    schema() {
      return [
        { value: 'service', text: this.$t('views.paymentServices.table.service'), align: 'center' },
        { value: 'type', text: this.$t('views.paymentServices.table.type'), align: 'center' },
        { value: 'name', text: this.$t('views.paymentServices.table.name'), attrs: { } },
        { value: 'use_tds', text: this.$t('views.paymentServices.table.useTds'), align: 'center' },
        { value: 'created_at', text: this.$t('views.paymentServices.table.createdAt'), attrs: { } },
        { value: 'actions', text: this.$t('views.paymentServices.table.actions'), attrs: { } }
      ];
    },

    creationList() {
      return [
        { service: 'linepay', title: 'LINE Pay', subtitle: this.$t('views.paymentServices.createLinePayDesc'), icon: require('@/assets/icons/linepay.png') },
        { service: 'jko', title: 'JKO Pay', subtitle: this.$t('views.paymentServices.createJkoDesc'), icon: require('@/assets/icons/jko-pay.svg') },
        { service: 'icashpay', title: 'icash Pay', subtitle: this.$t('views.paymentServices.createIcashPayDesc'), icon: require('@/assets/icons/icash-pay.svg') },
        { service: 'pxpayplus', title: 'PxPay Plus', subtitle: this.$t('views.paymentServices.createPxPayPlusDesc'), icon: require('@/assets/icons/pxpayplus.png') },
        { service: 'pluspay', title: 'Plus Pay', subtitle: this.$t('views.paymentServices.createPlusPayDesc'), icon: require('@/assets/icons/pluspay.png') },
        { service: 'easywallet', title: 'EasyWallet', subtitle: this.$t('views.paymentServices.createEasyWalletDesc'), icon: require('@/assets/icons/easywallet.png') },
        { service: 'tappay', title: 'TapPay', subtitle: this.$t('views.paymentServices.createTapPayDesc'), icon: require('@/assets/icons/tappay.png') },
        { service: 'ipassmoney', title: 'iPASS MONEY', subtitle: this.$t('views.paymentServices.createIPassMoneyDesc'), icon: require('@/assets/icons/ipassmoney.png') }
      ];
    },
    crudFormItems() {
      const formItems = [
        { label: 'Name(必填)', key: 'name', display: ["online", "offline"] },
      ];

      switch (this.dialog.crud.service) {
        case 'linepay': {
          formItems.push({ label: 'Channel ID', key: 'channelId', display: ["online", "offline"]  });
          formItems.push({ label: 'Channel Secret', key: 'channelSecret', display: ["online", "offline"]  });
          break;
        }
        case 'jko': {
          //this.formItems.push(...jkoItem);
          formItems.push({ label: 'Store ID(必填)', key: 'storeId', display: ["online", "offline"] });
          formItems.push({ label: 'API Key (非必填)', key: 'apiKey', display: ["online"] });
          formItems.push({ label: 'API Secret (非必填)', key: 'apiSecret', display: ["online"]});
          formItems.push({ label: "API URL 系統方名稱(非必填)", key: "apiServiceName", display: ["offline"] });
          formItems.push({ label: "MID(非必填)", key: "jkoMerchantId", display: ["offline"] });
          formItems.push({ label: "Merchant Key(非必填)", key: "merKey", display: ["offline"] });
          break;
        }
        case 'icashpay': {
          formItems.push({ label: 'ICP Merchant ID', key: 'icpMerchantId', display: ["online", "offline"]  });
          formItems.push({ label: '店家ID (非必填)', key: 'icpStoreId', display: ["online", "offline"]  });
          formItems.push({ label: '店名（App上會顯示的店名）', key: 'icpStoreName', display: ["online", "offline"]  });
          break;
        }
        case 'pxpayplus': {
          formItems.push({ label: 'Store ID(必填)', key: 'storeId', display: ["online", "offline"]  });
          formItems.push({ label: 'Merchant Code', key: 'merchantCode', display: ["online", "offline"]  });
          formItems.push({ label: '店家英文名稱', key: 'merEnName', display: ["online", "offline"]  });
          break;
        }
        case 'pluspay': {
          formItems.push({ label: 'Store ID(必填)', key: 'storeId', display: ["online", "offline"]  });
          break;
        }
        case 'icashpay': {
          formItems.push({ label: 'ICP Merchant ID', key: 'icpMerchantId', display: ["online", "offline"]  });
          formItems.push({ label: '店家ID (非必填)', key: 'icpStoreId', display: ["online", "offline"]  });
          formItems.push({ label: '店名（App上會顯示的店名）', key: 'icpStoreName', display: ["online", "offline"]  });
          break;
        }
        case 'pxpayplus': {
          formItems.push({ label: 'Store ID(必填)', key: 'storeId', display: ["online", "offline"]  });
          formItems.push({ label: 'Merchant Code', key: 'merchantCode', display: ["online", "offline"]  });
          formItems.push({ label: '店家英文名稱', key: 'merEnName', display: ["online", "offline"]  });
          break;
        }
        case 'pluspay': {
          this.formItems.push({ label: 'Store ID(必填)', key: 'storeId', display: ["online", "offline"]  });
          break;
        }
        case 'easywallet': {
          formItems.push({ label: 'Seller Member UID', key: 'sellerMemberUid', display: ["online", "offline"]  });
          formItems.push({ label: 'Security Code', key: 'securityCode', display: ["online", "offline"]  });
          formItems.push({ label: 'Contract No', key: 'contractNo', display: ["online", "offline"]  });
          formItems.push({ label: 'Admin Username', key: 'executorId', display: ["online", "offline"]  });
          break;
        }
        case 'tappay': {
          formItems.push({ label: 'TapPay Merchant ID', key: 'tappayMerchantId', canUpdate: false, display: ["online", "offline"]  });
          formItems.push({ label: 'Contact Name', key: 'contactName', canUpdate: false, display: ["online", "offline"]  });
          formItems.push({ label: 'Contact Phone', key: 'contactPhone', canUpdate: false , display: ["online", "offline"] });
          formItems.push({ label: 'Contact Address', key: 'contactAddress', canUpdate: false, display: ["online", "offline"]  });
          break;
        }
        case 'taiwanpay': {
          formItems.push({ label: '特店代碼', key: 'focasMerchantId', display: ["online", "offline"]  });
          formItems.push({ label: '端末代碼', key: 'focasTerminalId', display: ["online", "offline"]  });
          formItems.push({ label: '財金平台驗證參數', key: 'focasVerifyParam', display: ["online", "offline"]  });
          formItems.push({ label: '金融資訊系統驗證參數', key: 'qrpVerifyParam', display: ["online", "offline"]  });
          break;
        }
        case 'ipassmoney': {
          formItems.push({ label: "MID(必填)", key: "ipmMerchantId", display: ["online", "offline"]  });
          formItems.push({ label: "Key(必填)", key: "key", display: ["online", "offline"]  });
          break;
        }
        default:
      }

      return formItems;
    },
    supportTypes() {
      return {
        online: this.dialog.crud.service === 'ipassmoney' ? false : true, 
        offline: true
      }
    }
  },

  validators: {
    'dialog.crud.model.name': v => {
      return Validator.value(v).required().minLength(4);
    },
    // LINE Pay
    'dialog.crud.model.channelId': v => {
      return Validator.value(v).required().length(10);
    },
    'dialog.crud.model.channelSecret': v => {
      return Validator.value(v).required().length(32);
    },
    // JKO Pay, PxPayPlus, PlusPay
    'dialog.crud.model.storeId': v => {
      return Validator.value(v).required().maxLength(40);
    },
    // JKO
    'dialog.crud.model.apiKey': v => {
      return Validator.value(v).maxLength(64);
    },
    'dialog.crud.model.apiSecret': v => {
      return Validator.value(v).maxLength(64);
    },
    'dialog.crud.model.apiServiceName': v => {
      // DB最大到256
      return Validator.value(v).maxLength(256);
    },
    'dialog.crud.model.jkoMerchantId': v => {
      return Validator.value(v).maxLength(256);
    },
    'dialog.crud.model.merKey': v => {
      return Validator.value(v).maxLength(256);
    },
    // PxPayPlus
    'dialog.crud.model.merchantCode': v => {
      return Validator.value(v).required().maxLength(16);
    },
    'dialog.crud.model.merEnName': v => {
      return Validator.value(v).required().maxLength(128);
    },
    // ICASH Pay
    'dialog.crud.model.icpMerchantId': v => {
      return Validator.value(v).required().maxLength(10);
    },
    'dialog.crud.model.icpStoreId': v => {
      return Validator.value(v).required().maxLength(64);
    },
    'dialog.crud.model.icpStoreName': v => {
      return Validator.value(v).required().maxLength(64);
    },
    // EasyWallet
    'dialog.crud.model.sellerMemberUid': v => {
      return Validator.value(v).required().length(16);
    },
    'dialog.crud.model.securityCode': v => {
      return Validator.value(v).required().length(64);
    },
    'dialog.crud.model.contractNo': v => {
      return Validator.value(v).required().length(16);
    },
    'dialog.crud.model.executorId': v => {
      return Validator.value(v).required();
    },
    // TapPay
    'dialog.crud.model.tappayMerchantId': v => {
      return Validator.value(v).required().minLength(8);
    },
    'dialog.crud.model.contactName': v => {
      return Validator.value(v).required();
    },
    'dialog.crud.model.contactPhone': v => {
      return Validator.value(v).required();
    },
    'dialog.crud.model.contactAddress': v => {
      return Validator.value(v).required();
    },
    // iPassMoney 一卡通
    'dialog.crud.model.ipmMerchantId': v => {
      return Validator.value(v).required();
    },
    'dialog.crud.model.key': v => {
      return Validator.value(v).required();
    },
  }
}
</script>
